

































































































import { TwButton, WizardTabs } from '@/app/components';
import { Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import { OrbitSpinner, SelfBuildingSquareSpinner } from 'epic-spinners';
import { AssetsUnavailableModal } from '../components/';
import { useRetrievalAvailableAssets, useRetrievalQuery } from '../composable';
import { RetrievalStatus } from '../constants';

export default defineComponent({
    name: 'ConfigureRetrievalQuery',
    metaInfo() {
        return {
            title: `Configure Retrieval Query${
                (this as any).retrievalQuery ? ` : ${(this as any).retrievalQuery.title}` : ''
            }`,
        };
    },
    components: {
        OrbitSpinner,
        SelfBuildingSquareSpinner,
        WizardTabs,
        TwButton,
        AssetsUnavailableModal,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    setup(props, { root }) {
        const PARAMS_STEP: string = 'step';
        const id: Ref<string> = computed(() => props.id);
        const {
            retrievalQuery,
            loading,
            accessibility,
            configuration,
            hasChanges,
            assets: selectedAssetIds,
            refetch,
            save: saveQuery,
        } = useRetrievalQuery(id);

        const {
            assets,
            totalResults,
            loading: loadingAssets,
            allAssets,
            hiddenSelectedAssetIds,
            hiddenSelectedAssets,
            refetch: refetchAssets,
            get,
            set,
            updateSearchQuery,
        } = useRetrievalAvailableAssets(accessibility, root);

        const tabs = computed(() => accessibility.value?.steps);
        const defaultStep = computed(() => (tabs.value && tabs.value.length > 0 ? tabs.value[0].key : undefined));
        const step: Ref<string | undefined> = ref<string | undefined>();

        const currentTab: Ref<number> = computed({
            get: () => {
                return step.value && tabs.value && tabs.value.length > 0
                    ? tabs.value.findIndex((t: { title: string; component: any; key: string }) => t.key === step.value)
                    : 0;
            },
            set: (newTab: number) => {
                step.value = tabs.value && tabs.value.length > newTab ? tabs.value[newTab].key : defaultStep.value;
                set(PARAMS_STEP, step.value, defaultStep.value);
            },
        });

        const save = () => {
            return new Promise<void>((resolve, reject) => {
                if (props.id) {
                    saveQuery()
                        .then(() => {
                            (root as any).$toastr.s('Query successfully saved!', 'Success');
                            resolve();
                        })
                        .catch((e: any) => {
                            (root as any).$toastr.e('Error updating query!', 'Error');
                            if (e?.response?.status === 403) root.$router.push({ name: 'retrieval' });
                            reject(e);
                        });
                }
            });
        };

        const previous = () => {
            currentTab.value = currentTab.value > 0 ? currentTab.value - 1 : 0;
        };

        const next = () => {
            if (hasChanges.value) save().then(() => (currentTab.value = currentTab.value + 1));
            else currentTab.value = currentTab.value + 1;
        };

        const tabComponent = computed(() =>
            accessibility.value ? accessibility.value.steps[currentTab.value].component() : undefined,
        );

        const saveAndRefetch = async () => {
            save()
                .then(() => refetch().then(() => refetchAssets(selectedAssetIds.value, true)))
                .catch(() => (root as any).$toastr.e('Error updating query!', 'Error'));
        };

        watch(
            () => defaultStep.value,
            (newDefaultStep: string | undefined) => {
                if (newDefaultStep && tabs.value && tabs.value.length > 0) {
                    const predefinedStep = get(PARAMS_STEP, false, newDefaultStep);
                    if (
                        tabs.value.some((t: { title: string; component: any; key: string }) => t.key === predefinedStep)
                    )
                        step.value = predefinedStep;
                    else set(PARAMS_STEP, newDefaultStep, newDefaultStep);
                }
            },
        );

        // initial fetch

        refetch()
            .then(() => refetchAssets(selectedAssetIds.value, true))
            .catch((e: any) => {
                if (e?.response?.status === 403)
                    (root as any).$toastr.e(
                        'You do not have access to configure the specific retrieval query.',
                        'Access Forbidden!',
                    );
                else if (e?.response?.data?.message) (root as any).$toastr.e(e.response.data.message);
                root.$router.push({ name: 'retrieval' });
            });

        return {
            loading,
            loadingAssets,
            allAssets,
            hiddenSelectedAssetIds,
            hiddenSelectedAssets,
            retrievalQuery,
            tabs,
            currentTab,
            configuration,
            assets,
            totalResults,
            tabComponent,
            accessibility,
            hasChanges,
            saveAndRefetch,
            save,
            next,
            previous,
            RetrievalStatus,
            updateSearchQuery,
        };
    },
});
